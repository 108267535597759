<template>
    <div class="h-full">
        <div :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config" @onChangeEditDataSource="onChangeEditDataSource"></Control>
        </div>
        <div v-show="config.isDetailDisplay" class="detail">
            <EditControl :dataSource="editDataSource" :config="config"></EditControl>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    isDetailDisplay: false,
                    moduleName: 'EnterpriseUser.SysUser',
                    moduleEvent:{
                        initial:'EnterpriseUser:PageList',
                        add:'EnterpriseUser:Add'
                    }
                },
                editDataSource: {},
                initialDataSource: {}
            }
        },
        methods: {
            onChangeEditDataSource: function (dataSource) {
                this.editDataSource = dataSource;
                this.initialDataSource = this.Utils.extend({},dataSource);
            },
            syncDataSource(dataSource){
                this.editDataSource = dataSource;
            },
            checkSaveStatus() {
                this.config.isDetailDisplay = false;
            }
        },
        components: {
            "EditControl": resolve => { require(['./components/edituser.vue'], resolve) },
            "Control": resolve => { require(['./components/user.vue'], resolve) },
        }
    }
</script>

<style>

</style>